import { FileSystemNodeTypeEnum } from '@/types/constants/form';
import { createSlice } from '@reduxjs/toolkit';
import {
  FolderName,
  FolderType,
} from '../form/formTemplates/formTemplates.interface';

type SubmissionTemplateState = {
  templateId: number | null;
  selectedSubmissionTemplateFolder: {
    id: number | null;
    type: FolderType | FileSystemNodeTypeEnum | 'folder';
    name: string;
    parentId?: number;
  };
  selectedDestinationSubmissionTemplateFolder: {
    id: number | null;
    type: FolderType | FileSystemNodeTypeEnum | 'folder';
    name: string;
    parentId?: number;
  };
  destinationProjectId: number | null;
  folderDialogContext: string | null;
  selectedTemplateOrFolder: {
    id: number | null;
    type: FileSystemNodeTypeEnum;
    name: string;
  };
  selectedRows: string[];
  isDeleteFrom: 'emptyTrash' | 'single' | null;
  deleteForeverContext: 'emptyTrash' | 'single' | null;
};

const initialState: SubmissionTemplateState = {
  templateId: null,
  selectedSubmissionTemplateFolder: {
    id: null,
    type: FolderType.AllTemplates,
    name: FolderName.AllTemplates,
    parentId: undefined,
  },
  selectedDestinationSubmissionTemplateFolder: {
    id: null,
    type: FolderType.AllTemplates,
    name: FolderName.AllTemplates,
    parentId: undefined,
  },
  destinationProjectId: null,
  folderDialogContext: null,
  selectedTemplateOrFolder: {
    id: null,
    type: FileSystemNodeTypeEnum.Folder,
    name: '',
  },
  selectedRows: [],
  isDeleteFrom: null,
  deleteForeverContext: null,
};

const submissionTemplateSlice = createSlice({
  name: 'submissionTemplate',
  initialState,
  reducers: {
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setSelectedSubmissionTemplateFolder: (state, action) => {
      state.selectedSubmissionTemplateFolder = action.payload;
    },
    setSelectedDestinationSubmissionTemplateFolder: (state, action) => {
      state.selectedDestinationSubmissionTemplateFolder = action.payload;
    },
    setFolderDialogContext: (state, action) => {
      state.folderDialogContext = action.payload;
    },
    setSelectedTemplateOrFolder: (state, action) => {
      state.selectedTemplateOrFolder = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setIsDeleteFrom: (state, action) => {
      state.isDeleteFrom = action.payload;
    },
    setDeleteForeverContext: (state, action) => {
      state.deleteForeverContext = action.payload;
    },
    setDestinationProjectId: (state, action) => {
      state.destinationProjectId = action.payload;
    },
  },
});

export const {
  setTemplateId,
  setSelectedSubmissionTemplateFolder,
  setSelectedDestinationSubmissionTemplateFolder,
  setFolderDialogContext,
  setSelectedTemplateOrFolder,
  setDeleteForeverContext,
  setDestinationProjectId,
  setSelectedRows,
  setIsDeleteFrom,
} = submissionTemplateSlice.actions;

export default submissionTemplateSlice.reducer;
