import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { comparePages } from './submissionsHelper';

export interface IChange {
  fieldId: string;
  fieldName: string;
  oldValue: string;
  newValue: string;
  pageName?: string;
  pageNumber?: number;
  pageId?: string;
}

export interface CompareResult {
  hasChanges: boolean;
  changedFields: IChange[];
}

export const compareMainPages = createAsyncThunk(
  'submissions/compareMainPages',
  async (pages: any[], { getState }) => {
    const state = getState() as RootState;
    return comparePages(pages, state?.submissions?.initialPages || []);
  },
);

export const compareSubFormPages = createAsyncThunk(
  'submissions/compareSubFormPages',
  async (pages: any[], { getState }) => {
    const state = getState() as RootState;
    return comparePages(pages, state?.submissions?.initialSubFormPages || []);
  },
);
