import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const recordApi = createApi({
  reducerPath: 'recordAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createRecord: builder.mutation<any, any>({
      query: (data) => ({
        url: '/api/DevOps/Record',
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
  }),
});

export const { useCreateRecordMutation } = recordApi;
