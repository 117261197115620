import { FileSystemNodeTypeEnum } from '@/types/constants/form';
import { IUser } from '@/types/constants/user';
import { FormPageData } from '../formTemplateSetting/formTemplateSetting.interface';

export interface GetTemplatesByPageFilerRequest {
  id?: number;
  ids?: number[];
  keyword?: string;
  pageCount?: number;
  pageNumber?: number;
  groupId?: number;
  parentId?: number;
  orderByField?: string;
  isOrderByDesc?: boolean;
}

export interface GetTemplatesByPageFilter {
  currentPageCount: number;
  pageNumber: number;
  pageCount: number;
  totalCount: number;
  totalPageCount: number;
  data: {
    id: number;
    type: any;
    name: string;
    ownerId: number;
    modified: string;
    path: string;
  }[];
  users: IUser[];
}

export interface GetFormFolderContents {
  folderId: number;
  folderName: string;
  folderContents: {
    id: number;
    name: string;
    type: FileSystemNodeTypeEnum;
    ownerId: number;
    modified: string;
  }[];
  users: IUser[];
}

export interface BaseFolderInfo {
  id: number;
  name: string;
}

export interface FormFolder extends BaseFolderInfo {
  childrenFolders: FormFolder[];
}

export interface GetFormTrashed {
  trashedContents: {
    id: string;
    name: string;
    type: FileSystemNodeTypeEnum;
    ownerId: number;
    modified: string;
  }[];
  users: IUser[];
}

export interface CreateFormTemplateRequest {
  parentId?: number;
  name: string;
}

export interface CreateFormFolderRequest {
  parentId?: number;
  name: string;
}

export interface GetFormTemplateFoldersByPageFilterRequest {
  groupId?: number;
  pageCount?: number;
  pageNumber?: number;
  keyword?: string;
  orderByField?: string;
  isOrderByDesc?: boolean;
}

export interface GetFormFoldersByPageFilterResponse {
  currentPageCount: number;
  data: {
    id: number;
    modified: string;
    name: string;
    ownerId: number;
    path: string | null;
    type: number;
  }[];
  pageCount: number;
  pageNumber: number;
  totalCount: number;
  totalPageCount: number;
  users: IUser[];
}

export interface RenameFormFolderRequest {
  id: number;
  type: FileSystemNodeTypeEnum;
  name: string;
  parentId?: number; // 需要觸發tag用的
}

export interface MoveToTrashRequest {
  id: number;
  type: FileSystemNodeTypeEnum;
  parentId?: number; // 需要觸發tag用的
}

export interface DuplicateFormTemplateRequest {
  templateId: number;
  folderId?: number;
}

export interface DeleteForeverRequest {
  id: number;
  type: FileSystemNodeTypeEnum;
}

export interface RestoreTemplateOrFolderRequest {
  id: number;
  type: FileSystemNodeTypeEnum;
  parentId?: number; // 需要觸發tag用的
}

export enum FolderId {
  AllFolder = -1,
  Trash = -2,
  AllTemplates = 0,
}

export enum FolderType {
  AllFolder = 'allFolder' ,
  Trash = 'trash',
  AllTemplates = 'allTemplates',
}

export enum FolderName {
  AllFolder = 'All Folders',
  Trash = 'Trash',
  AllTemplates = 'All Templates',
}

export interface Folder {
  id: FolderId;
  type: FolderType;
  name: FolderName;
}

export interface BatchActionRequest {
  contents: BatchContents[];
  parentId?: number;
}

export interface BatchContents {
  id: number;
  type: FileSystemNodeTypeEnum;
}

export interface SaveAsNewRequest {
  parentId: number;
  name: string;
  pages: FormPageData[];
  referenceNumberPrefix: string;
  referenceNumberSuffix: string;
  referenceNumberStartsFrom: string;
  validTimeStepId: string;
  validTimeDays: number;
  rootNodeId: string;
  nodes: any[];
  edges: any[];
  validation: Validation;
}

export interface Validation {
  isAllSendEmail: boolean;
  isAllButtonClose: boolean;
  isAllBackToPreviousClose: boolean;
  isAllCreateNewVersionClose: boolean;
  isAllClose: boolean;
}
