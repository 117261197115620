import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiResponse } from '@/types/interface';
import {
  GetNotifyMessagesByFilterRequest,
  NotifyMessageResponse,
} from './interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

const URL = '/api/NotifyMessage';

export const notifyMessageApi = createApi({
  reducerPath: 'notifyMessageApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['NotifyMessage'],
  endpoints: (builder) => ({
    getNotifyMessagesByFilter: builder.query<
      NotifyMessageResponse,
      GetNotifyMessagesByFilterRequest
    >({
      query: (params) => ({
        url: `${URL}/GetNotifyMessagesByFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: any): NotifyMessageResponse => {
        return {
          data: response?.data?.data ?? [],
          errorCode: response?.data?.errorCode ?? 0,
          message: response?.data?.message ?? '',
        };
      },
      providesTags: (result, error, arg) => [
        { type: 'NotifyMessage', id: `PAGE_${arg.pageNumber}` },
        { type: 'NotifyMessage', id: 'LIST' },
      ],
    }),
    getNotifyMessagesCountByFilter: builder.query<ApiResponse<number>, any>({
      query: (params) => ({
        url: `${URL}/GetNotifyMessagesCountByFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        { type: 'NotifyMessage', id: 'LIST' },
      ],
    }),
    updateNotifyMessageAllIsRead: builder.mutation<ApiResponse<any>, any>({
      query: (params) => ({
        url: `${URL}/UpdateNotifyMessageAllIsRead`,
        method: 'PUT',
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'NotifyMessage', id: 'LIST' },
      ],
    }),
    updateNotifyMessageIsRead: builder.mutation<ApiResponse<any>, any>({
      query: (data) => ({
        url: `${URL}/UpdateNotifyMessageIsRead`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'NotifyMessage', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetNotifyMessagesByFilterQuery,
  useGetNotifyMessagesCountByFilterQuery,
  useUpdateNotifyMessageAllIsReadMutation,
  useUpdateNotifyMessageIsReadMutation,
} = notifyMessageApi;
