import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';

interface PDFPrintTestRequest {
  pageSize: 'A1' | 'A2' | 'A3' | 'A4' | 'A5';
  marginLeft: string; // mm
  marginRight: string; // mm
  marginTop: string; // mm
  marginBottom: string; // mm
  landscape: boolean; // 是否橫向
  isDisplayHeaderPageInfo: boolean;
  isDisplayFooterLogo: boolean;
  isDisplayFooterLink: boolean;
  htmlBody: string; // html 內容
}

interface DownloadSubmissionPdfLinkRequest {
  SubmissionId: string;
  Version?: string;
  CreatedBy?: string;
}

interface DownloadSubmissionPdfLinkResponse {
  submissionPdfUrl: string;
}

export const pdfApi = createApi({
  reducerPath: 'pdfApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    downloadTestPdf: builder.mutation<Blob, PDFPrintTestRequest>({
      query: (data) => ({
        url: '/api/Test/TestPdf',
        method: 'POST',
        data,
        responseType: 'arraybuffer', // 使用 arraybuffer 接收二進制數據
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        // 將 arraybuffer 轉換為 Blob
        return new Blob([response], { type: 'application/pdf' });
      },
    }),
    downloadSubmissionPdfLink: builder.mutation<
      DownloadSubmissionPdfLinkResponse,
      DownloadSubmissionPdfLinkRequest
    >({
      query: ({ SubmissionId }) => ({
        url: '/api/SubmissionPdf/Link',
        method: 'GET',
        params: { SubmissionId },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const downloadPdf = async (blob: Blob, response: Response) => {
  try {
    // 從回應標頭取得檔案名稱，如果沒有則使用預設名稱
    const contentDisposition = response.headers?.get('content-disposition');
    let filename = 'download.pdf';

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }
    }

    // 檢查 PDF 格式
    const reader = new FileReader();
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
      const header = String.fromCharCode.apply(null, Array.from(arr));

      if (header !== '%PDF') {
        console.error('Invalid PDF format');
        return;
      }

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = decodeURIComponent(filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };

    reader.readAsArrayBuffer(blob);
    return true;
  } catch (error) {
    console.error('PDF Download Error:', error);
    return false;
  }
};

export const downloadFromUrl = async (url: string, filename?: string) => {
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/pdf',
        'Content-Disposition': `attachment; filename="${filename || 'download.pdf'}"`,
      },
    });
    
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = filename || 'download.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error('Download error:', error);
  }
};

export const {
  useDownloadTestPdfMutation,
  useDownloadSubmissionPdfLinkMutation,
} = pdfApi;
