import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formTemplateSettingApi } from '../form/formTemplateSetting/formTemplateSettingAPI';
import { submissionTemplateSettingApi } from '../submissionTemplates/submissionTemplateSettingAPI';

interface InitialState {
  editorMode: boolean;
  scale: number;
  layout: {
    paperSize: 'A1' | 'A2' | 'A3' | 'A4' | 'A5';
    paperColor: string;
    orientation: 1 | 2; // 1:portrait | 2: landscape;
    defaultMarginSetting: boolean;
    top: number;
    bottom: number;
    left: number;
    right: number;
    marginGuide: boolean;
    isShowPageNumbers: boolean;
  };
  content: string;
  lastUpdateTimestamp: number; // 新增時間戳記來追蹤更新
}

const initialState: InitialState = {
  editorMode: false,
  scale: 1,
  layout: {
    paperSize: 'A4',
    paperColor: '#ffffff',
    orientation: 1,
    defaultMarginSetting: true,
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
    marginGuide: true,
    isShowPageNumbers: true,
  },
  content: '',
  lastUpdateTimestamp: 0, // 新增時間戳記來追蹤更新
};

const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    setPDFEditMode: (state, action: PayloadAction<boolean>) => {
      // 切換編輯模式時不清除內容
      state.editorMode = action.payload;
    },
    setScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload;
    },
    setPaperSize: (
      state,
      action: PayloadAction<'A1' | 'A2' | 'A3' | 'A4' | 'A5'>,
    ) => {
      state.layout.paperSize = action.payload;
    },
    setPaperColor: (state, action: PayloadAction<string>) => {
      state.layout.paperColor = action.payload;
    },
    setOrientation: (state, action: PayloadAction<1 | 2>) => {
      state.layout.orientation = action.payload;
    },
    setDefaultMarginSetting: (state, action: PayloadAction<boolean>) => {
      state.layout.defaultMarginSetting = action.payload;
      if (action.payload === true) {
        state.layout.top = 10;
        state.layout.bottom = 10;
        state.layout.left = 10;
        state.layout.right = 10;
      }
    },
    setMarginGuide: (state, action: PayloadAction<boolean>) => {
      state.layout.marginGuide = action.payload;
    },
    setShowPageNumbers: (state, action: PayloadAction<boolean>) => {
      state.layout.isShowPageNumbers = action.payload;
    },
    setTop: (state, action: PayloadAction<number>) => {
      state.layout.top = action.payload;
    },
    setLeft: (state, action: PayloadAction<number>) => {
      state.layout.left = action.payload;
    },
    setRight: (state, action: PayloadAction<number>) => {
      state.layout.right = action.payload;
    },
    setBottom: (state, action: PayloadAction<number>) => {
      state.layout.bottom = action.payload;
    },
    updateContent: (state, action: PayloadAction<string>) => {
      state.content = action.payload;
      state.lastUpdateTimestamp = Date.now();
    },
    resetToDefault: (state) => {
      return initialState;
    },
    setPDFLayout: (
      state,
      action: PayloadAction<Partial<typeof initialState.layout>>,
    ) => {
      state.layout = {
        ...state.layout,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {

    const parseMarginValue = (value: string | null | undefined): number => {
      if (!value) return 10;
      const numValue = value.replace('mm', '');
      const parsed = parseInt(numValue, 10);
      return isNaN(parsed) ? 10 : parsed;
    };

    const handlePdfPayload = (state: InitialState, payload: any) => {
      if (payload) {
        // 設定 layout 相關資訊
        state.layout = {
          ...state.layout,
          paperSize: payload.paperSize || 'A4',
          paperColor: payload.paperColor || '#FFFFFF',
          orientation: payload.orientation || 1,
          top: parseMarginValue(payload.marginTop),
          bottom: parseMarginValue(payload.marginBottom),
          left: parseMarginValue(payload.marginLeft),
          right: parseMarginValue(payload.marginRight),
          isShowPageNumbers: payload.isShowPageNumbers || true,
        };

        if ('content' in payload && typeof payload.content === 'string') {
          state.content = payload.content;
        }
      }
    };

    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormPdfDetail.matchFulfilled,
      (state, { payload }) => {
        handlePdfPayload(state, payload);
      }
    );

    builder.addMatcher(
      submissionTemplateSettingApi.endpoints.getSubmissionFormPdfDetail.matchFulfilled,
      (state, { payload }) => {
        handlePdfPayload(state, payload);
      }
    );
  },
});

export const {
  setPDFEditMode,
  setScale,
  setPaperSize,
  setPaperColor,
  setOrientation,
  setDefaultMarginSetting,
  setMarginGuide,
  setShowPageNumbers,
  setTop,
  setLeft,
  setRight,
  setBottom,
  updateContent,
  resetToDefault,
  setPDFLayout,
} = pdfSlice.actions;

export default pdfSlice.reducer;
