import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BatchActionRequest,
  CreateFormFolderRequest,
  CreateFormTemplateRequest,
  DeleteForeverRequest,
  DuplicateFormTemplateRequest,
  FormFolder,
  GetFormFolderContents,
  GetFormTemplateFoldersByPageFilterRequest,
  GetFormTrashed,
  GetTemplatesByPageFilerRequest,
  GetTemplatesByPageFilter,
  MoveToTrashRequest,
  RenameFormFolderRequest,
  RestoreTemplateOrFolderRequest,
  SaveAsNewRequest,
} from '@/lib/form/formTemplates/formTemplates.interface';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';

export const formTemplatesApi = createApi({
  reducerPath: 'formTemplatesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'FormFolders',
    'FormTemplate',
    'FolderContents',
    'FormTrash',
    'FormPhoto',
  ],
  endpoints: (builder) => ({
    getFormFolders: builder.query<FormFolder[], void>({
      query: () => ({
        url: `/api/FormTemplates/GetFormFolders`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['FormFolders'],
    }),
    getFormFolderContents: builder.query<GetFormFolderContents, any>({
      query: (folderId) => ({
        url: `/api/FormTemplates/GetFormFolderContents/${folderId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: (result, error, folderId) => [
        { type: 'FolderContents', id: folderId },
      ],
    }),
    getFormTemplateFoldersByPageFilter: builder.query<
      any,
      GetFormTemplateFoldersByPageFilterRequest
    >({
      query: (params) => ({
        url: `/api/FormTemplates/GetFormTemplateFoldersByPageFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['FolderContents'],
    }),
    getFormTemplatesByPageFilter: builder.query<
      GetTemplatesByPageFilter,
      GetTemplatesByPageFilerRequest
    >({
      query: (params) => {
        // 複製原始參數
        const urlParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => urlParams.append(key, v));
          } else {
            urlParams.append(key, value);
          }
        });
        return {
          url: `/api/FormTemplates/GetFormTemplatesByPageFilter`,
          method: 'GET',
          params: urlParams,
        };
      },
      transformResponse: (response) => response.data.data,
      providesTags: ['FormTemplate'],
    }),
    getFormTrashed: builder.query<GetFormTrashed, void>({
      query: () => ({
        url: `/api/FormTemplates/GetFormTrashed`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['FormTrash'],
    }),
    createFormTemplate: builder.mutation<any, CreateFormTemplateRequest>({
      query: (data) => ({
        url: `/api/FormTemplates`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormFolders' as const },
            { type: 'FormTemplate' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
    createFolder: builder.mutation<any, CreateFormFolderRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/CreateFolder`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { parentId }) => [
        { type: 'FormFolders' as const },
        { type: 'FolderContents' as const },
        ...(parentId
          ? [{ type: 'FolderContents' as const, id: parentId }]
          : []),
      ],
    }),
    renameContent: builder.mutation<any, RenameFormFolderRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/RenameContent`,
        method: 'PUT',
        data: { id: data.id, type: data.type, name: data.name },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormFolders' as const },
            { type: 'FormTemplate' as const },
            { type: 'FolderContents' as const },
            { type: 'FolderContents' as const, id: arg.parentId },
          ]),
        );
      },
    }),
    moveToTrash: builder.mutation<any, MoveToTrashRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/MoveToTrash`,
        method: 'DELETE',
        data: {
          id: data.id,
          type: data.type,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormFolders' as const },
            { type: 'FormTemplate' as const },
            { type: 'FormTrash' as const },
            { type: 'FolderContents' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
    duplicateFormTemplate: builder.mutation<any, DuplicateFormTemplateRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/Duplicate/${data.templateId}`,
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormTemplate' as const },
            ...(arg.folderId
              ? [{ type: 'FolderContents' as const, id: arg.folderId }]
              : []),
          ]),
        );
      },
    }),
    deleteForever: builder.mutation<any, DeleteForeverRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/DeleteForever`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormTrash' as const },
          ]),
        );
      },
    }),
    restoreTemplateOrFolder: builder.mutation<
      any,
      RestoreTemplateOrFolderRequest
    >({
      query: (data) => ({
        url: `/api/FormTemplates/Restore`,
        method: 'POST',
        data: {
          id: data.id,
          type: data.type,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormTrash' as const },
            { type: 'FormTemplate' as const },
            { type: 'FormFolders' as const },
            { type: 'FolderContents' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
    clearTrashed: builder.mutation<any, void>({
      query: () => ({
        url: `/api/FormTemplates/ClearTrashed`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(formTemplatesApi.util.invalidateTags(['FormTrash' as const]));
      },
    }),
    assignCollectionPhotosToTemplate: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/FormTemplates/AssignCollectionPhotosToTemplate`,
        method: 'POST',
        data,
      }),
    }),
    batchRestore: builder.mutation<any, BatchActionRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/BatchRestore`,
        method: 'PUT',
        data: { contents: data.contents },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormTrash' as const },
            { type: 'FormTemplate' as const },
            { type: 'FormFolders' as const },
            { type: 'FolderContents' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
    batchDeleteForever: builder.mutation<any, BatchActionRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/BatchDeleteForever`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormTrash' as const },
          ]),
        );
      },
    }),
    batchMoveToTrash: builder.mutation<any, BatchActionRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/BatchMoveToTrash`,
        method: 'DELETE',
        data: {
          contents: data.contents,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormFolders' as const },
            { type: 'FormTemplate' as const },
            { type: 'FormTrash' as const },
            { type: 'FolderContents' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
    saveAsNew: builder.mutation<any, SaveAsNewRequest>({
      query: (data) => ({
        url: `/api/FormTemplates/SaveAsNew`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplatesApi.util.invalidateTags([
            { type: 'FormFolders' as const },
            { type: 'FormTemplate' as const },
            { type: 'FormTrash' as const },
            { type: 'FolderContents' as const },
            ...(arg.parentId
              ? [{ type: 'FolderContents' as const, id: arg.parentId }]
              : []),
          ]),
        );
      },
    }),
  }),
});

export const {
  useGetFormFoldersQuery,
  useGetFormFolderContentsQuery,
  useGetFormTemplateFoldersByPageFilterQuery,
  useGetFormTemplatesByPageFilterQuery,
  useGetFormTrashedQuery,
  useCreateFormTemplateMutation,
  useCreateFolderMutation,
  useRenameContentMutation,
  useMoveToTrashMutation,
  useDuplicateFormTemplateMutation,
  useDeleteForeverMutation,
  useRestoreTemplateOrFolderMutation,
  useClearTrashedMutation,
  useAssignCollectionPhotosToTemplateMutation,
  useBatchRestoreMutation,
  useBatchDeleteForeverMutation,
  useBatchMoveToTrashMutation,
  useSaveAsNewMutation,
} = formTemplatesApi;
