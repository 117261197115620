import { atomizeChangeset, diff } from 'json-diff-ts';
import { CompareResult, IChange } from './submissionsThunks';

// helper functions for submissions
export function normalizeValue(value: any): string {
  if (!value) return '';
  if (Array.isArray(value) && value.length === 0) return '';
  return String(value);
}

// 處理不同類型元件的值轉換
export function formatComponentValue(component: any): string {
  if (!component?.value) return '';

  switch (component.type) {
    case 'dateTime': {
      const value = component.value;
      if (value.startsDate && value.endsDate) {
        return `${new Date(value.startsDate).toLocaleDateString()}-${new Date(value.endsDate).toLocaleDateString()}`;
      } else if (value.date) {
        return new Date(value.date).toLocaleDateString();
      }
      return '';
    }
    case 'multipleSelection':
    case 'dropdown':
    case 'singleSelection':
      return Array.isArray(component.value)
        ? component.value.join(', ')
        : String(component.value || '');
    case 'signature': {
      if (component.value.signature) {
        return '1 attachment';
      }
      return '';
    }
    case 'upload': {
      if (
        component.value.photos.length > 0 ||
        component.value.files.length > 0
      ) {
        return 'Upload files';
      }
      return '';
    }
    default:
      return String(component.value || '');
  }
}

export function findComponentByPath(obj: any, path: string): any {
  // 移除前綴 $.$root
  const cleanPath = path
    .replace(/^\$\.\$root\[(\d+)\]\./, '')
    .replace(/\[(\d+)\]/g, '.$1')
    .split('.')
    .filter((part) => part !== '$' && part !== '$root');

  let current = obj;

  for (const part of cleanPath) {
    if (!current) return undefined;
    const index = parseInt(part);
    if (!isNaN(index)) {
      // 如果是 components 陣列，需要特別處理多維陣列的情況
      if (Array.isArray(current.components)) {
        if (Array.isArray(current.components[index])) {
          // 處理多維陣列
          current = current.components[index][0];
        } else {
          current = current.components[index];
        }
      } else if (Array.isArray(current)) {
        current = current[index];
      }
    } else {
      current = current[part];
    }
  }
  return { component: current, value: current };
}

function findParentComponent(obj: any, path: string): any {
  // 移除 value 部分取得父元件路徑
  const parentPath = path.split('.value')[0];

  // 往上找到最近的 inputTable
  let current = parentPath;
  while (current) {
    const { component } = findComponentByPath(obj, current);
    if (component?.type === 'inputTable') {
      return component;
    }
    // 往上一層
    const lastDotIndex = current.lastIndexOf('.');
    if (lastDotIndex === -1) break;
    current = current.substring(0, lastDotIndex);
  }

  return null;
}

interface InputTableChange extends IChange {
  changeCount: number;
}

export function comparePages(
  currentPages: any[],
  initialPages: any[],
): CompareResult {
  const differences = diff(initialPages, currentPages, {
    keysToSkip: ['errors'],
  });

  const atomicChanges = atomizeChangeset(differences);
  const fieldChangesMap = new Map<string, IChange | InputTableChange>();

  atomicChanges
    .filter((change) => change.path.includes('.value'))
    .forEach((change) => {
      const pageIndex = Number(change.path.split('[')[1]?.split(']')[0] || 0);
      const page = currentPages[pageIndex];
      const initialPage = initialPages[pageIndex];

      const inputTableComponent = findParentComponent(page, change.path);

      const componentPath = change.path.split('.value')[0];
      const { component: currentComponent } = findComponentByPath(
        page,
        componentPath,
      );

      const { component: initialComponent } = findComponentByPath(
        initialPage,
        componentPath,
      );
      if (inputTableComponent) {
        const fieldKey = `${page.id}_${inputTableComponent.id}`;
        fieldChangesMap.set(fieldKey, {
          fieldId: inputTableComponent.id,
          fieldName: inputTableComponent.value?.label || 'Input Table',
          oldValue: '',
          newValue: '',
          pageName: page.name || '',
          pageNumber: pageIndex + 1,
          pageId: page.id || '',
        });
      } else {
        if (!currentComponent) return;

        const oldValue = formatComponentValue(initialComponent);
        const newValue = formatComponentValue(currentComponent);

        if (normalizeValue(oldValue) !== normalizeValue(newValue)) {
          const fieldKey = `${page.id}_${currentComponent.id}`;
          fieldChangesMap.set(fieldKey, {
            fieldId: currentComponent.id,
            fieldName: currentComponent.setting?.label || currentComponent.type,
            oldValue,
            newValue,
            pageName: page.name || '',
            pageNumber: pageIndex + 1,
            pageId: page.id || '',
          });
        }
      }
    });

  const changedFields = Array.from(fieldChangesMap.values());
  return {
    hasChanges: changedFields.length > 0,
    changedFields,
  };
}
