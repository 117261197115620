import {
  Brief,
  SubmissionDetail,
  SubmissionTodoTypeEnum,
} from '@/lib/submissions/submissions.interface';
import { submissionApi } from '@/lib/submissions/submissionsAPI';
import { submissionShareLinkApi } from '@/lib/submissionShareLink/submissionShareLinkAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareMainPages, compareSubFormPages } from './submissionsThunks';

interface InitialState {
  pages: any[] | null;
  initialPages: any[] | null;
  hasPagesIsChanged: boolean;
  changedFields: any[] | null;
  subFormPages: any[] | null;
  initialSubFormPages: any[] | null;
  hasSubFormPagesIsChanged: boolean;
  changedFieldsSubForm: any[] | null;
  isTrashed: boolean;
  submissionVersions: {
    [submissionId: string]: string[];
  };
  selectedVersions: {
    [submissionId: string]: string;
  };
  submissionVersionsSubForm: {
    [submissionId: string]: string[];
  };
  selectedVersionsSubForm: {
    [submissionId: string]: string;
  };
  isMenuIconVisible: boolean;
  submissionDetail: SubmissionDetail;
  submissionDetailSubForm: any | null;
  tempResource: {
    photos: any[];
    files: any[];
  };
  submissionIds: number[];
  brief: Brief | null;
  briefSubForm: Brief | null;
  copyStepData: any | null;
  processDetails: {
    [submissionId: string]: {
      [version: string]: any;
    };
  };
  processDetailsSubForm: {
    [submissionId: string]: {
      [version: string]: any;
    };
  };
  formTodoCount: {
    allCount: number;
    myTurnCount: number;
    onGoingCount: number;
    doneCount: number;
  };
  formTodoSubmissionIds: number[];
  formTodoCurrentSubmissionId: number | null;
  formTodoCurrentProjectId: number | null;
  formTodoSubmissionProjectMap: Record<number, number>;
  selectedSubmissionId: number | null;
}

const createSubmissionDetailInitialState = (): SubmissionDetail => ({
  created: '',
  templateName: '',
  projectId: 0,
  submissionId: 0,
  templateId: 0,
  submitter: {
    userId: 0,
    fullName: '',
    email: '',
    photoUrl: '',
    abbreviationBgColor: '',
  },
  submittedAt: '',
  photos: [],
  files: [],
  referenceNumberStr: '',
  currentStepId: '',
  expirationAt: '',
  validTimeStart: null,
  validTimeEnd: null,
  isLastStep: false,
  validUntilState: 0,
  expirationState: 0,
  updated: '',
  pages: [],
  currentStepTeams: [],
  currentStepActions: [],
  subformSubmissions: [],
});

const initialState: InitialState = {
  pages: [],
  initialPages: [],
  hasPagesIsChanged: false,
  changedFields: [],
  subFormPages: [],
  initialSubFormPages: [],
  hasSubFormPagesIsChanged: false,
  changedFieldsSubForm: [],
  isTrashed: false,
  submissionVersions: {},
  selectedVersions: {},
  submissionVersionsSubForm: {},
  selectedVersionsSubForm: {},
  isMenuIconVisible: false,
  tempResource: {
    photos: [],
    files: [],
  },
  submissionDetail: createSubmissionDetailInitialState(),
  submissionDetailSubForm: createSubmissionDetailInitialState(),
  submissionIds: [],
  brief: null,
  briefSubForm: null,
  copyStepData: null,
  processDetails: {},
  processDetailsSubForm: {},
  formTodoCount: {
    allCount: 0,
    myTurnCount: 0,
    onGoingCount: 0,
    doneCount: 0,
  },
  formTodoSubmissionIds: [],
  formTodoCurrentSubmissionId: null,
  formTodoCurrentProjectId: null,
  formTodoSubmissionProjectMap: {},
  selectedSubmissionId: null,
};

interface ComponentMapping {
  sourceId: string;
  targetId: string;
  value: any;
  components?: { id: string; value: any }[][];
  tableComponents?: {
    rowId: string;
    componentId: string;
    value: any;
  }[][][];
}

const checkIsCurrentStepField = (
  component: any,
  currentStepId: string,
): boolean => {
  if (component.type === 'table') {
    // 檢查 table 內部的 components
    return component.components?.some((group: any[]) =>
      group.some((row: any) =>
        row.components?.some((comp: any) =>
          comp.setting?.steps?.some(
            (step: { id: string }) => step.id === currentStepId,
          ),
        ),
      ),
    );
  }

  return component.setting?.steps?.some(
    (step: { id: string }) => step.id === currentStepId,
  );
};

const copySubmissionValues = (
  sourcePages: any[],
  targetPages: any[],
  currentStepId: string,
): any[] => {
  const updatedPages = JSON.parse(JSON.stringify(targetPages));
  const mappings: ComponentMapping[] = [];

  // 第一步：建立欄位映射關係，但只針對當前步驟
  sourcePages.forEach((sourcePage) => {
    sourcePage.components?.forEach((sourceRow) => {
      if (sourceRow.type === 'row') {
        sourceRow.components?.forEach((sourceComponent) => {
          if (sourceComponent.type === 'signature') return;

          // 檢查是否屬於當前步驟
          const isCurrentStepField = checkIsCurrentStepField(
            sourceComponent,
            currentStepId,
          );
          if (isCurrentStepField) {
            if (sourceComponent.type === 'inputTable') {
              updatedPages.forEach((targetPage) => {
                targetPage.components?.forEach((targetRow) => {
                  targetRow.components?.forEach((targetComponent) => {
                    if (targetComponent.type === 'inputTable') {
                      const sourceComponents = sourceComponent.components.map(
                        (row: any[]) =>
                          row.map((cell: any) => ({
                            id: cell.id,
                            value: cell.value,
                          })),
                      );

                      mappings.push({
                        sourceId: sourceComponent.id,
                        targetId: targetComponent.id,
                        value: sourceComponent.value,
                        components: sourceComponents,
                      });
                    }
                  });
                });
              });
            } else if (sourceComponent.type === 'table') {
              updatedPages.forEach((targetPage) => {
                targetPage.components?.forEach((targetRow) => {
                  targetRow.components?.forEach((targetComponent) => {
                    if (targetComponent.type === 'table') {
                      const tableComponents = sourceComponent.components.map(
                        (group: any[]) =>
                          group.map((row: any) =>
                            row.components.map((comp: any) => ({
                              rowId: row.id,
                              componentId: comp.id,
                              value: comp.value,
                            })),
                          ),
                      );

                      mappings.push({
                        sourceId: sourceComponent.id,
                        targetId: targetComponent.id,
                        value: sourceComponent.value,
                        tableComponents,
                      });
                    }
                  });
                });
              });
            } else {
              // 原本處理一般欄位的邏輯
              updatedPages.forEach((targetPage) => {
                targetPage.components?.forEach((targetRow) => {
                  targetRow.components?.forEach((targetComponent) => {
                    const isTargetCurrentStepField =
                      targetComponent.setting?.steps?.some(
                        (step: { id: string }) => step.id === currentStepId,
                      );

                    if (
                      isTargetCurrentStepField &&
                      sourceComponent.setting?.label ===
                        targetComponent.setting?.label
                    ) {
                      mappings.push({
                        sourceId: sourceComponent.id,
                        targetId: targetComponent.id,
                        value: sourceComponent.value,
                      });
                    }
                  });
                });
              });
            }
          }
        });
      }
    });
  });

  // 第二步：更新目標頁面的值
  mappings.forEach((mapping) => {
    updatedPages.forEach((page: any, pageIndex: number) => {
      page.components?.forEach((row, rowIndex) => {
        if (row.type === 'row') {
          row.components?.forEach((component, componentIndex) => {
            if (component.id === mapping.targetId) {
              if (component.type === 'inputTable' && mapping.components) {
                // 更新 inputTable 的值
                const updatedComponents = component.components.map(
                  (targetRow: any[], rowIndex: number) =>
                    targetRow.map((cell: any, cellIndex: number) => ({
                      ...cell,
                      value:
                        mapping.components?.[rowIndex]?.[cellIndex]?.value ??
                        cell.value,
                    })),
                );

                updatedPages[pageIndex].components[rowIndex].components[
                  componentIndex
                ] = {
                  ...component,
                  value: mapping.value,
                  components: updatedComponents,
                };
              } else if (
                component.type === 'table' &&
                mapping.tableComponents
              ) {
                // 更新 table 的值
                const updatedComponents = component.components.map(
                  (group: any[], groupIndex: number) =>
                    group.map((row: any, rowIndex: number) => ({
                      ...row,
                      components: row.components.map(
                        (comp: any, compIndex: number) => ({
                          ...comp,
                          value:
                            mapping.tableComponents?.[groupIndex]?.[rowIndex]?.[
                              compIndex
                            ]?.value ?? comp.value,
                        }),
                      ),
                    })),
                );

                updatedPages[pageIndex].components[rowIndex].components[
                  componentIndex
                ] = {
                  ...component,
                  value: mapping.value,
                  components: updatedComponents,
                };
              } else {
                // 更新一般欄位的值
                updatedPages[pageIndex].components[rowIndex].components[
                  componentIndex
                ] = {
                  ...component,
                  value: mapping.value,
                };
              }
            }
          });
        }
      });
    });
  });

  return updatedPages;
};

const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    setFilledSubmissionPages: (state, action) => {
      state.pages = action.payload;
    },
    setFilledSubFormPages: (state, action) => {
      state.subFormPages = action.payload;
    },
    setIsTrashed: (state, action) => {
      state.isTrashed = action.payload;
    },
    setSelectedVersion: (
      state,
      action: PayloadAction<{ submissionId: string; version: string }>,
    ) => {
      state.selectedVersions[action.payload.submissionId] =
        action.payload.version;
    },
    setSelectedVersionSubForm: (
      state,
      action: PayloadAction<{ submissionId: string; version: string }>,
    ) => {
      state.selectedVersionsSubForm[action.payload.submissionId] =
        action.payload.version;
    },
    setIsMenuIconVisible: (state, action) => {
      state.isMenuIconVisible = action.payload;
    },
    setTempResource: (state, action) => {
      state.tempResource = action.payload;
    },
    setCopyStepData: (state, action) => {
      state.copyStepData = action.payload;
    },
    setCopyStepDataToCurrentSubmission: (state) => {
      if (state.submissionDetail && state.copyStepData) {
        const updatedPages = copySubmissionValues(
          state.copyStepData.pages,
          state.pages ?? [],
          state.submissionDetail.currentStepId,
        );
        state.pages = updatedPages;
        state.submissionDetail.photos = state.copyStepData.photos;
        state.submissionDetail.files = state.copyStepData.files;
      }
    },
    setFormTodoCurrentSubmissionId: (state, action) => {
      state.formTodoCurrentSubmissionId = action.payload;
    },
    setFormTodoSubmissionIds: (state, action) => {
      state.formTodoSubmissionIds = action.payload;
    },
    setFormTodoCurrentProjectId: (state, action) => {
      state.formTodoCurrentProjectId = action.payload;
    },
    setSelectedSubmissionId: (state, action) => {
      state.selectedSubmissionId = action.payload;
    },
    setCleanChangedFields: (state) => {
      state.changedFields = [];
    },
    setHasPagesIsChanged: (state, action) => {
      state.hasPagesIsChanged = action.payload;
    },
    setHasSubFormPagesIsChanged: (state, action) => {
      state.hasSubFormPagesIsChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(compareMainPages.fulfilled, (state, action) => {
      state.hasPagesIsChanged = action.payload.hasChanges;
      state.changedFields = action.payload.changedFields;
    });
    builder.addCase(compareSubFormPages.fulfilled, (state, action) => {
      state.hasSubFormPagesIsChanged = action.payload.hasChanges;
      state.changedFieldsSubForm = action.payload.changedFields;
    });
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionDetail.matchFulfilled,
      (state, action) => {
        state.pages = action.payload.pages ?? [];
        state.submissionDetail = action.payload;
        state.initialPages = action.payload.pages ?? [];
        // const differences = diff(state.initialPages, state.pages, {
        //   keysToSkip: ['errors'], // errors 是欄位格式錯誤，不列入比對
        // });
        // state.hasPagesIsChanged = differences.length > 0 ? true : false;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionDetailSubForm.matchFulfilled,
      (state, action) => {
        state.subFormPages = action.payload.pages ?? [];
        state.submissionDetailSubForm = action.payload;
        state.initialSubFormPages = action.payload.pages ?? [];
        // const differences = diff(
        //   state.initialSubFormPages,
        //   state.subFormPages,
        //   {
        //     keysToSkip: ['errors'], // errors 是欄位格式錯誤，不列入比對
        //   },
        // );
        // state.hasSubFormPagesIsChanged = differences.length > 0 ? true : false;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionProgress.matchFulfilled,
      (state, action) => {
        const submissionId = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersions[submissionId] = versions as string[];
        state.selectedVersions[submissionId] =
          versions[versions.length - 1] ?? 'A';
      },
    );
    builder.addMatcher(
      submissionShareLinkApi.endpoints.getShareLinkSubmissionProgress
        .matchFulfilled,
      (state, action) => {
        const { submissionId } = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersions[submissionId] = versions as string[];
        state.selectedVersions[submissionId] =
          (versions[versions.length - 1] as string) ?? 'A';
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionProgressSubForm.matchFulfilled,
      (state, action) => {
        const submissionId = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersionsSubForm[submissionId] = versions as string[];
        state.selectedVersionsSubForm[submissionId] =
          versions[versions.length - 1] ?? 'A';
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        state.submissionIds = action.payload.data.map(
          (submission) => submission.submissionId,
        );
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getBrief.matchFulfilled,
      (state, action) => {
        state.brief = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getBriefSubForm.matchFulfilled,
      (state, action) => {
        state.briefSubForm = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getProcessDetail.matchFulfilled,
      (state, action) => {
        const { submissionId, version } = action.meta.arg.originalArgs;

        // 初始化如果不存在
        if (!state.processDetails[submissionId]) {
          state.processDetails[submissionId] = {};
        }

        // 儲存特定版本的資料
        state.processDetails[submissionId][version] = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getProcessDetailSubForm.matchFulfilled,
      (state, action) => {
        const { submissionId, version } = action.meta.arg.originalArgs;
        // 初始化如果不存在
        if (!state.processDetailsSubForm[submissionId]) {
          state.processDetailsSubForm[submissionId] = {};
        }

        // 儲存特定版本的資料
        state.processDetailsSubForm[submissionId][version] = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionTodos.matchFulfilled,
      (state, action) => {
        if (!action.payload.data) return;
        state.formTodoSubmissionIds = action.payload.data.map(
          (todo) => todo.submissionId,
        );
        state.formTodoSubmissionProjectMap = action.payload.data.reduce(
          (acc, todo) => ({
            ...acc,
            [todo.submissionId]: todo.project.projectId,
          }),
          {},
        );
        const requestType = action.meta.arg.originalArgs.todoType;
        if (requestType === SubmissionTodoTypeEnum.AllTodo) {
          const allCount = action.payload.data.length;
          const myTurnCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.MyTurn,
          ).length;
          const onGoingCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.Ongoing,
          ).length;
          const doneCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.Done,
          ).length;

          state.formTodoCount = {
            allCount,
            myTurnCount,
            onGoingCount,
            doneCount,
          };
        }
      },
    );
  },
});

export const {
  setFilledSubmissionPages,
  setFilledSubFormPages,
  setSelectedVersion,
  setSelectedVersionSubForm,
  setIsMenuIconVisible,
  setIsTrashed,
  setTempResource,
  setCopyStepData,
  setCopyStepDataToCurrentSubmission,
  setFormTodoCurrentSubmissionId,
  setFormTodoSubmissionIds,
  setFormTodoCurrentProjectId,
  setSelectedSubmissionId,
  setCleanChangedFields,
  setHasPagesIsChanged,
  setHasSubFormPagesIsChanged,
} = submissionsSlice.actions;
export default submissionsSlice.reducer;
