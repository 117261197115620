import { FileSystemNodeTypeEnum } from '@/types/constants/form';
import { createSlice } from '@reduxjs/toolkit';
import { FolderName, FolderType } from './formTemplates.interface';

type FormTemplateState = {
  templateId: number | null;
  selectedTemplate: {
    id: number | null;
    name: string;
  };
  selectedFolder: {
    id: number | null;
    type: FolderType | FileSystemNodeTypeEnum | 'folder';
    name: string;
    parentId?: number;
  };
  selectedTemplateOrFolder: {
    id: number | null;
    type: FileSystemNodeTypeEnum;
    name: string;
  };
  folderDialogContext: 'root' | 'folder';
  deleteForeverContext: 'emptyTrash' | 'single' | 'batch' | null;
  selectedRows: number[];
  selectedRowsWithName: {
    id: number;
    name: string;
  }[];
  selectedSubformId: number | null;
  selectedMultipleRows: string[];
};

const initialState: FormTemplateState = {
  templateId: null,
  selectedTemplate: {
    id: null,
    name: '',
  },
  selectedFolder: {
    id: null,
    type: FolderType.AllTemplates,
    name: FolderName.AllTemplates,
    parentId: undefined,
  },
  selectedTemplateOrFolder: {
    id: null,
    type: FileSystemNodeTypeEnum.Template,
    name: '',
  },
  folderDialogContext: 'root',
  deleteForeverContext: null,
  selectedRows: [],
  selectedRowsWithName: [],
  selectedSubformId: null,
  selectedMultipleRows: [],
};

const formTemplateSlice = createSlice({
  name: 'formTemplate',
  initialState,
  reducers: {
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
    setSelectedTemplateOrFolder: (state, action) => {
      state.selectedTemplateOrFolder = action.payload;
    },
    setFolderDialogContext: (state, action) => {
      state.folderDialogContext = action.payload;
    },
    setDeleteForeverContext: (state, action) => {
      state.deleteForeverContext = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setSelectedRowsWithName: (state, action) => {
      state.selectedRowsWithName = action.payload;
    },
    setSelectedSubformId: (state, action) => {
      state.selectedSubformId = action.payload;
    },
    setSelectedMultipleRows: (state, action) => {
      state.selectedMultipleRows = action.payload;
    },
  },
});

export const {
  setTemplateId,
  setSelectedTemplate,
  setSelectedFolder,
  setSelectedTemplateOrFolder,
  setFolderDialogContext,
  setDeleteForeverContext,
  setSelectedRows,
  setSelectedRowsWithName,
  setSelectedSubformId,
  setSelectedMultipleRows,
} = formTemplateSlice.actions;

export default formTemplateSlice.reducer;
