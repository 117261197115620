import { createSlice } from "@reduxjs/toolkit";

interface SubmissionShareLinkState {
    submitterInfo: {
        email: string;
        name: string;
        company: string;
    }
}

const initialState: SubmissionShareLinkState = {
    submitterInfo: {
        email: '',
        name: '',
        company: ''
    }
}

const submissionShareLinkSlice = createSlice({
    name: 'submissionShareLink',
    initialState,
    reducers: {
        setSubmitterInfo: (state, action) => {
            state.submitterInfo = action.payload;
        }
    }
})

export const { setSubmitterInfo } = submissionShareLinkSlice.actions;
export default submissionShareLinkSlice.reducer;